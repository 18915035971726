body {
    margin: 0px;
}

/* :focus {
    outline: none;
    box-shadow: none;
} */

.withoutScrollbar::-webkit-scrollbar {
    display: none;
}

.sc-dmRaPn {
    height: calc(100vh - 285px);
}